import { useQuery } from "@tanstack/react-query"
import {
  Invoice,
  MedicationDispense,
  MedicationKnowledge,
  MedicationRequest,
  Provenance,
  ServiceRequest,
  Task,
  getResource,
  getResources,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { getBillingTypeCode, getCommonCode, getMedCodes } from "utils"

import { medsQueryKeys } from "../meds_query_keys"
import { TrackingData } from "../types"

const useMrOrderDetails = (patientId: string, orderId: string) => {
  const { search } = useClient()
  const queryKey = medsQueryKeys.orderDetails.details(orderId)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _query: "medication-order-details",
        _id: orderId,
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/ServiceRequest`, filters, signal })

      const serviceRequest = getResources<ServiceRequest>(bundle, "ServiceRequest")
      const medicationRequests = getResources<MedicationRequest>(bundle, "MedicationRequest")
      const medicationKnowledges = getResources<MedicationKnowledge>(bundle, "MedicationKnowledge")
      const tasks = getResources<Task>(bundle, "Task")
      const invoice = getResource<Invoice>(bundle, "Invoice")
      const medicationDispenses = getResources<MedicationDispense>(bundle, "MedicationDispense")
      const provenances = getResources<Provenance>(bundle, "Provenance")

      return {
        serviceRequest: serviceRequest?.[0],
        tasks,
        medicationRequests,
        medicationKnowledges,
        invoice,
        medicationDispenses,
        provenances,
      }
    },
    throwOnError: true,
    meta: { context: { queryKey, orderId } },
  })

  const { missingInfoMessages, medCodes, medicationKnowledges, billingTypeCode } = useMemo(() => {
    const taskDescriptions =
      data?.invoice?.status !== "balanced"
        ? data?.tasks.reduce((acc, task) => {
            if (
              ["complete-shipping-address", "complete-shipping-method", "complete-cc"].includes(
                task.code?.coding?.[0].code ?? "",
              ) &&
              task.status === "ready"
            )
              return [...acc, task.description as string]
            return acc
          }, new Array<string>())
        : undefined

    const medCodes = getMedCodes({ meds: data?.medicationRequests, withQty: true })
    const medicationKnowledges = data?.medicationKnowledges.reduce(
      (acc, mk) => {
        const code = getCommonCode({ codes: mk.code?.coding })
        return { ...acc, [code]: mk }
      },
      {} as Record<string, MedicationKnowledge>,
    )

    const billingTypeCode = getBillingTypeCode(data?.medicationRequests?.[0])

    return { missingInfoMessages: taskDescriptions, medCodes, medicationKnowledges, billingTypeCode }
  }, [data?.invoice?.status, data?.tasks, data?.medicationRequests, data?.medicationKnowledges])

  const { medProvenances, dispenseTrackCodes } = useMemo(() => {
    const dispenseTrackCodes =
      data?.medicationDispenses.reduce((acc, md) => {
        if (md.identifier?.[0]?.value) {
          const trackinCode = md.identifier[0].value
          const medication = md.medication?.CodeableConcept?.text as string

          return [
            ...acc,
            {
              identifier: trackinCode,
              datePrepared: md.whenPrepared as string,
              dateShipped: md.whenHandedOver,
              medications: [medication],
              status: md.status,
            },
          ]
        }
        return acc
      }, Array<TrackingData>()) ?? []

    const medProvenances =
      data?.provenances?.reduce<Record<string, Provenance[]>>((acc, p) => {
        const md = data?.medicationDispenses.find((md) => md.eventHistory?.some((ref) => ref.id === p.id))
        const mrId = md?.authorizingPrescription?.[0]?.id
        return mrId ? { ...acc, [mrId]: [...(acc[mrId] ?? []), p] } : acc
      }, {}) ?? {}

    return { medProvenances, dispenseTrackCodes }
  }, [data?.provenances, data?.medicationDispenses])

  return {
    serviceRequest: data?.serviceRequest,
    tasks: data?.tasks,
    medicationRequests: data?.medicationRequests,
    medicationKnowledges,
    invoice: data?.invoice,
    medicationDispenses: data?.medicationDispenses,
    dispenseTrackCodes,
    provenances: medProvenances,
    missingInfoMessages,
    medCodes,
    isLoading,
    billingTypeCode,
  }
}

export { useMrOrderDetails }
