import { faSearch } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CodeableConcept, ServiceRequest, codeableConceptAsString } from "fhir"
import { ErrorMessage, FieldArray, FieldArrayRenderProps, useFormikContext } from "formik"
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog"
import { classNames } from "primereact/utils"
import { useCallback, useEffect, useState } from "react"

import { AddFieldArrayItemButton } from "commons"
import { useBloodDrawnTests, useLoadProductsPrice } from "commons/hooks"
import { LaboratoryOrder, LaboratoryOrderPanel, PanelInfo, getClasifiedBDPanels } from "commons/labs"
import { LaboratoryTest } from "commons/types"
import { BILLING_TYPES_CODES, DEFAULT_BLOOD_DRAWN_PANELS_LIST } from "data"
import { useOrganizationContext } from "organization"

import { useLaboratoryCatalogs } from "../hooks"
import { LaboratoryOrderPanelItem } from "./LaboratoryOrderPanelItem"
import { LaboratoryOrderPanelSelection } from "./LaboratoryOrderPanelSelection"
import { LabOrderComboItem } from "./LabOrderComboItem"
import { getSrData, sanitizePanel } from "./validations"

const LaboratoryOrderPanels = ({ field, label }: Props) => {
  const { currentOrganizationId, isExemptLabPayment } = useOrganizationContext()
  const [showPanelSelection, setShowPanelSelection] = useState(false)

  const {
    values: {
      order: { performer, subject, insurance },
      billingType,
      panels: includedPanels,
      combo,
      deletedPanels,
      bloodDrawnInOffice,
    },
    initialValues,
    setFieldValue,
  } = useFormikContext<LaboratoryOrder>()

  const isInsurance = billingType === BILLING_TYPES_CODES.INSURANCE

  const showNoCatalogsWarning = () => {
    confirmDialog({
      message: "Selected laboratory has no tests available!",
      header: "Information",
      acceptLabel: "Accept",
      rejectClassName: "hidden",
      acceptClassName: "button-primary p-button-sm",
    })
  }

  const { count, isLoading } = useLaboratoryCatalogs(performer?.[0]?.id)
  const { bloodDrawnTests, isLoading: isLoadingBDTests } = useBloodDrawnTests(
    currentOrganizationId,
    DEFAULT_BLOOD_DRAWN_PANELS_LIST,
    billingType as BILLING_TYPES_CODES,
  )

  const isDBIOPanelActive = useCallback(
    (pIdOrCode: string) =>
      !bloodDrawnInOffice &&
      !!(
        bloodDrawnTests &&
        performer?.[0]?.id &&
        bloodDrawnTests[performer[0].id]?.some(
          ({ planDefinition }) =>
            planDefinition.id === pIdOrCode || planDefinition.identifier?.some(({ value }) => pIdOrCode === value),
        )
      ),
    [bloodDrawnTests, performer, bloodDrawnInOffice],
  )

  const getNewBDPanels = useCallback(
    () =>
      bloodDrawnTests?.[performer?.[0]?.id as string]?.reduce((prev, labTest) => {
        const profile = getSrData({
          patient: subject,
          performer: performer ?? [],
          planDefinition: labTest.planDefinition,
        })
        return [
          ...prev,
          { profile: sanitizePanel(profile), price: labTest.price, questionnaires: labTest.questionnaires },
        ]
      }, Array<LaboratoryOrderPanel>()),
    [performer?.[0]?.id],
  )

  useEffect(() => {
    if (!isLoading && !count) showNoCatalogsWarning()
  }, [count, isLoading])

  useEffect(() => {
    if (
      (performer?.[0].id !== combo?.laboratoryCombo.performer?.[0]?.id && !!combo) ||
      performer?.[0].id !== initialValues.order.performer?.[0]?.id
    ) {
      const panelsTodelete = includedPanels.filter((p) => p.profile.id) ?? []
      const comboPanelsToDelete = combo?.panels.filter((p) => p.profile.id) ?? []
      setFieldValue("deletedPanels", [...(deletedPanels ?? []), ...panelsTodelete, ...comboPanelsToDelete])
      setFieldValue("combo", undefined)
      const newBDPanels = !bloodDrawnInOffice ? getNewBDPanels() : []
      setFieldValue("panels", [...(newBDPanels ?? [])])
    }
  }, [performer?.[0]?.id])

  // When blood drawn changes handle add/delete BloodDrawn panels automatically
  useEffect(() => {
    let panels = [...includedPanels]
    const { bdPanels: panelsToDelete, nobdPanels: updatedPanels } = getClasifiedBDPanels(panels)

    if (!bloodDrawnInOffice) {
      if (performer?.[0]?.id && !isLoadingBDTests) {
        const newBDPanels = getNewBDPanels()
        panels = [...updatedPanels, ...(newBDPanels ?? [])]
        setFieldValue("deletedPanels", [...(deletedPanels ?? []), ...panelsToDelete])
      }
    } else {
      const { bdPanels: comboPanelsToDelete } = getClasifiedBDPanels(combo?.panels)
      panels = updatedPanels
      setFieldValue("deletedPanels", [...(deletedPanels ?? []), ...panelsToDelete, ...comboPanelsToDelete])
    }

    setFieldValue("panels", panels)
    setFieldValue("panelsCount", panels.length)
  }, [bloodDrawnInOffice, isLoadingBDTests])

  const { mapProductsPrice } = useLoadProductsPrice(
    currentOrganizationId,
    billingType ?? BILLING_TYPES_CODES.BILL_PATIENT,
    "panels",
    (data) => {
      setFieldValue("panels", data)
    },
  )

  useEffect(() => {
    if (!isExemptLabPayment) mapProductsPrice({ products: includedPanels })
  }, [billingType])

  return (
    <>
      <FieldArray name={field}>
        {({ push, remove, name, form: { values, errors, touched, setFieldValue } }: FieldArrayRenderProps) => {
          const fError = errors[name]
          const fTouched = touched[name]

          const allowAdd =
            performer?.[0].id !== undefined && ((isInsurance && insurance?.[0]?.id !== undefined) || !isInsurance)

          const updatePanels = (newCheckedItems: LaboratoryTest[], deletedItems: { id: string; index: number }[]) => {
            for (const pd of newCheckedItems) {
              const profile = getSrData({
                patient: subject,
                performer: performer ?? [],
                planDefinition: pd.planDefinition,
              })
              push({
                profile: sanitizePanel(profile),
                price: pd.price,
                questionnaires: pd.questionnaires,
                planDefinition: pd.planDefinition,
              })
            }

            const getCannonical = ({ planDefinition, profile }: PanelInfo) =>
              planDefinition
                ? `${planDefinition.url}|${planDefinition.version}`
                : (profile?.instantiatesCanonical?.[0] as string)

            for (const { id } of deletedItems) {
              const index = values[field].findIndex((i: PanelInfo) => getCannonical?.(i) === id)
              if (index !== -1) {
                removePanel(index)
              }
            }

            setShowPanelSelection(false)
          }

          const removePanel = (index: number, sr?: ServiceRequest) => {
            const test = values[field]?.[index]?.profile ?? sr
            let comboPanels: LaboratoryOrderPanel[] = []

            if (test.id === combo?.laboratoryCombo.id) {
              comboPanels = combo?.panels.filter((panel) => !!panel.profile.id) ?? []
              setFieldValue("combo", undefined)
              setFieldValue("deletedPanels", [...values["deletedPanels"], ...comboPanels])
            } else if (test.id) {
              setFieldValue("deletedPanels", [...values["deletedPanels"], values[field][index]])
            }
            if (
              (test?.code as CodeableConcept)?.coding?.some(({ code }) =>
                DEFAULT_BLOOD_DRAWN_PANELS_LIST.includes(code as string),
              )
            ) {
              setFieldValue("bloodDrawnInOffice", true)
            }

            if (index !== -1) {
              remove(index)
            }
          }

          return (
            <>
              <div className="field flex flex-col space-y-2 pb-3 pt-2">
                <div className="sticky top-0 bg-white z-10">
                  <div className="font-medium text-gray-900 text-lg">{label}</div>
                  <AddFieldArrayItemButton
                    disabled={!allowAdd || !count}
                    onClick={() => setShowPanelSelection(true)}
                    label="Select Additional Testing"
                    className="pl-6"
                  />
                </div>

                {!values?.panels?.length && !values.combo ? (
                  <div
                    className={classNames("flex flex-col items-center justify-center w-full py-3", {
                      "border rounded-md border-red-600": fError && fTouched,
                    })}
                  >
                    <FontAwesomeIcon icon={faSearch} size="lg" className="text-slate-500" />
                    <p className="text-slate-500 text-xs pt-1">No panels found</p>
                  </div>
                ) : (
                  <div
                    className={classNames("bg-white flex flex-col overflow-auto grow", {
                      "border rounded-md border-red-600": fError && fTouched,
                    })}
                  >
                    {values.panels.map((panel: LaboratoryOrderPanel, index: number) => {
                      const isReadonly = isDBIOPanelActive(panel.profile.code?.coding?.[0]?.code as string)
                      return (
                        <LaboratoryOrderPanelItem
                          key={panel.profile?.id ?? codeableConceptAsString(panel.profile.code)}
                          panel={panel}
                          onDelete={() => removePanel(index)}
                          isReadonly={isReadonly}
                          showPrice={!isExemptLabPayment}
                          className={classNames({ "pl-6": !!values.combo, "pr-4": isReadonly })}
                          isInsurance={isInsurance}
                        />
                      )
                    })}
                    {values?.combo && (
                      <LabOrderComboItem
                        combo={values.combo}
                        showPrice={!isExemptLabPayment}
                        isInsurance={isInsurance}
                        onDelete={(sr) => removePanel(-1, sr)}
                      />
                    )}
                  </div>
                )}

                <ErrorMessage name={name}>
                  {(msg) =>
                    typeof msg === "string" && (
                      <small id={`errorMessage.${name}`} className="p-error">
                        {msg}
                      </small>
                    )
                  }
                </ErrorMessage>
              </div>

              {showPanelSelection && (
                <LaboratoryOrderPanelSelection
                  billingType={billingType as BILLING_TYPES_CODES}
                  includedPanels={includedPanels}
                  laboratoryId={performer?.[0]?.id as string}
                  combo={combo}
                  onSave={updatePanels}
                  isPanelDisabled={isDBIOPanelActive}
                  onHide={() => setShowPanelSelection(false)}
                />
              )}
            </>
          )
        }}
      </FieldArray>
      <ConfirmDialog />
    </>
  )
}

type Props = {
  field: string
  label: string
}

export { LaboratoryOrderPanels }
