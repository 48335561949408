import { faExclamationTriangle, faSearch } from "@fortawesome/pro-regular-svg-icons"
import {
  faChevronDown,
  faChevronUp,
  faClockRotateLeft,
  faFileInvoiceDollar,
  faHandHoldingBox,
  faPaperPlane,
  faPills,
  faSyncAlt,
  faTrashCan,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { MedicationKnowledge, MedicationRequest, codeableConceptAsString } from "fhir"
import { Button } from "primereact/button"
import { Calendar } from "primereact/calendar"
import { Checkbox } from "primereact/checkbox"
import { Chip } from "primereact/chip"
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { TabPanel, TabView } from "primereact/tabview"
import { classNames } from "primereact/utils"
import { useCallback, useEffect, useMemo, useReducer, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { useChartContext } from "chart-view"
import { MedicationKnowledgeDetails, SkeletonLoader, useChargeItemDefinitions, useSendToPatient } from "commons"
import {
  MedicationKnowledgeImage,
  MedicationOrderDetailHeader,
  MedicationOrderTaskListItem,
  MedicationOrderTrackingInfo,
  MedicationRequestItem,
  useMedicationRequestDataBind,
  useMrOrderDetails,
  usePrescriptionMrOrder,
} from "commons/meds"
import { BILLING_TYPES_CODES } from "data"
import { InvoiceListItem } from "invoices"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"
import { SYSTEM_VALUES } from "system-values"
import { getMoneyCurrencyAlt, sumPrice } from "utils"

import { getStatusClass } from "../data"
import { useCancelMrOrder, useHoldMrOrder, useRescheduleMrOrder } from "../hooks"
import { RescheduleHistory } from "./orders/RescheduleHistory"

const MedicationOrderDetails = () => {
  const [expandedReason, setExpandedReason] = useState(false)
  const { patientId } = usePatientContext()
  const { currentOrganizationId } = useOrganizationContext()
  const {
    rescheduleDialogVisible,
    cancelDialogVisible,
    rescheduleDate,
    cancelReason,
    showRescheduleDialog,
    hideRescheduleDialog,
    showCancelDialog,
    hideCancelDialog,
    updateRescheduleDate,
    updateCancelReason,
  } = useStateReducer()

  const [params, setParams] = useSearchParams()

  const orderId = params.get("order")

  const [cancelFutureOrder, setCancelFutureOrder] = useState("skip")
  const [selectedMK, setSelectedMK] = useState<{ mk?: MedicationKnowledge; mr?: MedicationRequest }>()

  const handleOnHideCancel = () => {
    hideCancelDialog()
    setCancelFutureOrder("skip")
  }

  const {
    serviceRequest,
    medicationKnowledges,
    medicationRequests,
    medCodes,
    dispenseTrackCodes,
    tasks,
    invoice,
    isLoading,
    missingInfoMessages,
    billingTypeCode,
  } = useMrOrderDetails(patientId, orderId as string)
  const { chargeItemDefinitions } = useChargeItemDefinitions({
    organizationId: currentOrganizationId,
    codes: {
      ...(billingTypeCode === BILLING_TYPES_CODES.BILL_PATIENT
        ? { billToPatientCIDs: medCodes }
        : { billToPracticeOrInsuranceCIDs: medCodes }),
    },
  })

  const { medicationRequestData } = useMedicationRequestDataBind({
    medicationRequests,
    medicationKnowledges,
    medicationsCIDs:
      billingTypeCode === BILLING_TYPES_CODES.BILL_PATIENT
        ? chargeItemDefinitions?.billToPatientCIDs ?? {}
        : chargeItemDefinitions?.billToPracticeOrInsuranceCIDs ?? {},
  })

  const totalMedsPrice = useMemo(
    () =>
      medicationRequestData.reduce(
        (acc, medItem) => sumPrice(acc, medItem?.productPrice?.value ?? 0).sum.toNumber(),
        0,
      ),
    [medicationRequestData],
  )

  const { cancelMrOrder, isCancelling } = useCancelMrOrder(undefined, hideCancelDialog)
  const { holdMrOrder, isHolding } = useHoldMrOrder()
  const { rescheduleMrOrder, isRescheduling } = useRescheduleMrOrder()
  const { getPrescription, isGettingPrescription } = usePrescriptionMrOrder()
  const { sendOrderToPatient } = useSendToPatient()

  const { setAlertBannerMessage } = useChartContext()

  const confirmAction = (action: string, accept: () => void) => {
    confirmDialog({
      message: `Are you sure you want to ${action}?`,
      header: "Confirmation",
      acceptLabel: "Ok",
      rejectLabel: "Cancel",
      rejectClassName: "button-default p-button-sm",
      acceptClassName: "button-primary p-button-sm",
      accept: accept,
    })
  }
  const onlyPellets = medicationRequests?.every((item) => item.dispenseRequest?.initialFill?.isDfo === true)

  const showInvoice = useCallback(() => {
    params.delete("subview")
    params.delete("order")
    params.set("view", "invoice")
    params.set("invoiceId", invoice?.id ?? "")
    setParams(params)
  }, [invoice, params, setParams])

  const activeActions = useMemo(() => {
    const rescheduleDateString =
      serviceRequest?.occurrence?.dateTime ?? serviceRequest?.authoredOn ?? new Date().toISOString()
    let rescheduleDate = new Date(rescheduleDateString)
    if (rescheduleDate < new Date()) rescheduleDate = new Date()

    const dropdownItems = [
      {
        label: "Prescription",
        icon: <FontAwesomeIcon icon={faPills} size="sm" className="mr-2" />,
        command: () => getPrescription({ serviceRequestId: orderId as string }),
        loading: isGettingPrescription,
      },
      {
        label: "Reschedule",
        icon: <FontAwesomeIcon icon={faClockRotateLeft} size="sm" className="mr-2" />,
        disabled: invoice?.status === "balanced",
        command: () => {
          updateRescheduleDate(rescheduleDate)
          showRescheduleDialog()
        },
        loading: isRescheduling,
      },
      {
        label: "Refresh Prescription",
        icon: <FontAwesomeIcon icon={faSyncAlt} size="sm" className="mr-2" />,
        command: () => getPrescription({ serviceRequestId: orderId as string, forceRefresh: true }),
      },
      {
        label: "Hold",
        icon: <FontAwesomeIcon icon={faHandHoldingBox} size="sm" className="mr-2" />,
        disabled: invoice?.status === "balanced",
        command: () => confirmAction("hold on this order", () => holdMrOrder(orderId as string)),
      },
      ...(invoice
        ? [
            {
              label: "See Invoice",
              icon: <FontAwesomeIcon icon={faFileInvoiceDollar} size="sm" className="mr-2" />,
              command: showInvoice,
            },
            {
              label: "Send to patient",
              icon: <FontAwesomeIcon icon={faPaperPlane} size="sm" className="mr-2" />,
              command: () =>
                confirmAction("send this order to the patient", () => sendOrderToPatient(invoice?.id ?? "")),
            },
          ]
        : []),
      {
        label: "Cancel",
        icon: <FontAwesomeIcon icon={faTrashCan} size="sm" className="mr-2" />,
        command: showCancelDialog,
      },
    ]

    return serviceRequest?.status === "active"
      ? dropdownItems
      : dropdownItems.filter((item) => item.label === "Prescription")
  }, [
    orderId,
    serviceRequest,
    holdMrOrder,
    getPrescription,
    showCancelDialog,
    showRescheduleDialog,
    updateRescheduleDate,
    showInvoice,
    sendOrderToPatient,
    invoice,
    isGettingPrescription,
    isRescheduling,
  ])

  useEffect(() => {
    setAlertBannerMessage(
      missingInfoMessages && missingInfoMessages.length > 0 && (
        <div className="flex space-x-10 items-center justify-center">
          <div className="flex flex-col">
            {missingInfoMessages.map((msg, index) => (
              <span key={index}>{msg}</span>
            ))}
          </div>
          <div className="flex flex-row justify-end">
            <button className="underline ml-4" onClick={showInvoice}>
              See invoice
            </button>
          </div>
        </div>
      ),
    )

    return () => {
      setAlertBannerMessage()
    }
  }, [missingInfoMessages, setAlertBannerMessage, showInvoice])

  const shippingMethod = serviceRequest?.orderDetail?.find(({ coding }) =>
    ([SYSTEM_VALUES.SHIPPING_METHOD, SYSTEM_VALUES.SHIPPING_METHOD_DEFAULT] as string[]).includes(
      coding?.[0]?.system ?? "",
    ),
  )

  useEffect(() => {
    // Close all dialogs when order status changes from 'active'
    if (serviceRequest?.status !== "active") {
      hideCancelDialog()
      hideRescheduleDialog()
    }
  }, [serviceRequest?.status])

  return (
    <div className="flex flex-col h-full p-3 overflow-hidden">
      {isLoading ? (
        <>
          <SkeletonLoader loaderType="two-lines" extraLine repeats={3} />
        </>
      ) : (
        <div className="@container flex flex-col flex-1 gap-2 pb-6 overflow-y-scroll text-gray-600">
          <MedicationOrderDetailHeader
            className="flex flex-col gap-1"
            serviceRequest={serviceRequest}
            invoice={invoice}
            activeActions={activeActions}
            menuIsLoading={isCancelling || isHolding}
          />
          <TabView className="flex flex-col h-full" panelContainerClassName="grow">
            <TabPanel header={<span className="text-sm">Details</span>}>
              <div className="flex flex-col gap-4 overflow-y-scroll p-1 pt-4">
                <div className="flex flex-col p-2 border rounded-lg">
                  <div className="flex flex-col">
                    <div className="flex justify-between">
                      <label className="font-semibold mb-2 pr-1 p-4">Medications:</label>
                      {!!totalMedsPrice && (
                        <span className="text-gray-300 text-xs" title="Medication Totals">
                          {`${getMoneyCurrencyAlt(invoice?.totalGross?.currency)}${totalMedsPrice.toFixed(2)}`}
                        </span>
                      )}
                    </div>
                  </div>
                  {!medicationRequestData?.length ? (
                    <div className="flex flex-col items-center justify-center w-full h-full">
                      <FontAwesomeIcon icon={faSearch} size="3x" className="text-slate-400" />
                      <p className="text-md text-slate-400 pt-4 pb-2 place-self-center">No medications requested</p>
                    </div>
                  ) : (
                    <div className="grid grid-cols-1 @3xl:grid-cols-2 gap-2 2xl:gap-3 content-start pl-4 pb-2">
                      {medicationRequestData?.map((medicationData) => {
                        const dosageInstructions = medicationData.medicationRequestInfo.dosageInstruction

                        return (
                          <div
                            key={medicationData.medicationRequestInfo.id}
                            className="border border-slate-300 rounded-xl p-3 xl:p-5 grid grid-flow-col grid-cols-4 gap-2 lg:gap-4"
                          >
                            <div className="flex col-span-3 ">
                              <span
                                className={classNames(
                                  "w-24 min-w-[6rem] h-42 flex items-center justify-center",
                                  medicationData.medicationKnowledge && "cursor-pointer",
                                )}
                                onClick={() =>
                                  setSelectedMK({
                                    mk: medicationData.medicationKnowledge,
                                    mr: medicationData.medicationRequestInfo,
                                  })
                                }
                              >
                                <MedicationKnowledgeImage
                                  drugCharacteristic={medicationData.medicationKnowledge?.drugCharacteristic}
                                  className="w-full h-full object-cover"
                                />
                              </span>
                              <MedicationRequestItem
                                medicationRequest={medicationData.medicationRequestInfo}
                                medicationKnowledge={medicationData.medicationKnowledge ?? {}}
                                showPackagingType
                                amount={
                                  medicationData.medicationRequestInfo.dispenseRequest?.quantity?.value ??
                                  medicationData.medicationRequestInfo.dispenseRequest?.initialFill?.quantity?.value ??
                                  1
                                }
                                className="ml-2 xl:ml-3"
                                onClick={() =>
                                  setSelectedMK({
                                    mk: medicationData.medicationKnowledge,
                                    mr: medicationData.medicationRequestInfo,
                                  })
                                }
                              />
                            </div>
                            <div className="flex flex-col gap-2 row-start-1 col-span-1 justify-start items-end">
                              {!!medicationData.productPrice?.value && (
                                <span title="Price" className="font-semibold text-lg">
                                  {getMoneyCurrencyAlt(medicationData.productPrice.currency)}
                                  {medicationData.productPrice.value.toFixed(2)}
                                </span>
                              )}
                              <span title="Status">
                                <Chip
                                  label={medicationData.medicationRequestInfo.status}
                                  className={`custom-chip default capitalize ${getStatusClass(
                                    medicationData.medicationRequestInfo.status,
                                  )}`}
                                />
                              </span>
                            </div>
                            <div
                              className={classNames("row-start-2 col-start-1 col-span-4", {
                                "pl-24": dosageInstructions?.length === 1,
                              })}
                            >
                              {dosageInstructions?.length && (
                                <div
                                  title="Instructions"
                                  className="inline-flex flex-wrap gap-x-4 max-w-sm ml-2 xl:ml-3"
                                >
                                  {dosageInstructions?.map((instruction, index) => (
                                    <p key={instruction.id ?? index}>{instruction.text}</p>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>

                {!!serviceRequest?.reasonCode?.length && (
                  <div className="border p-4 rounded-lg transition-all ease-in-out duration-1000">
                    <div className="flex justify-between">
                      <label>Reason codes</label>
                      <div
                        className="flex gap-4 text-gray-900 cursor-pointer"
                        onClick={() => setExpandedReason(!expandedReason)}
                      >
                        <span className="text-sm text-gray-300">{expandedReason ? "Show less" : "Show more"} </span>
                        <FontAwesomeIcon icon={expandedReason ? faChevronUp : faChevronDown} />
                      </div>
                    </div>

                    <div className={classNames("text-gray-400", { hidden: !expandedReason })}>
                      <ul className="mt-3 flex flex-wrap gap-x-8 whitespace-nowrap list-disc pl-4 text-sm">
                        {serviceRequest.reasonCode.map((reason, index) => (
                          <li
                            key={
                              reason.id ??
                              (reason.coding?.[0].code && reason.coding?.[0].code + index) ??
                              (reason?.text && reason.text + index)
                            }
                            className="mb-1 p-1 last:mb-3 text-wrap whitespace-normal break-words"
                          >
                            {reason.coding
                              ? `${codeableConceptAsString(reason)} - ${reason.coding?.[0]?.code}`
                              : reason.text}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}

                {!!tasks?.length && (
                  <div className="flex flex-col pr-1 border rounded-lg p-4">
                    <label className="font-semibold ml-2 ">Tasks:</label>
                    <ul>{tasks?.map((task) => <MedicationOrderTaskListItem key={task.id} task={task} />)}</ul>
                  </div>
                )}

                <div className="flex flex-col pr-1 border rounded-lg p-4">
                  <label className="font-semibold ml-2">Shipping Method:</label>
                  <span className="ml-2 pt-4 pl-2">
                    {onlyPellets
                      ? "No apply"
                      : shippingMethod
                        ? codeableConceptAsString(shippingMethod)
                        : "No shipping method"}
                  </span>
                </div>

                <MedicationOrderTrackingInfo trackingCodes={dispenseTrackCodes} />

                {invoice && (
                  <span className="flex flex-col pr-1 border p-4 rounded-lg">
                    <label className="font-semibold ml-2 ">Invoice: </label>
                    <ul>{<InvoiceListItem invoice={invoice} onClick={showInvoice} className="last:mb-0" />}</ul>
                  </span>
                )}
              </div>
            </TabPanel>
            <TabPanel
              contentClassName="h-full flex flex-1"
              header={<span className="text-sm">Reschedule History</span>}
            >
              <RescheduleHistory orderId={orderId as string} />
            </TabPanel>
          </TabView>
        </div>
      )}
      <MedicationKnowledgeDetails
        selectedMK={selectedMK?.mk}
        mr={selectedMK?.mr}
        onHide={() => setSelectedMK(undefined)}
      />
      <ConfirmDialog />
      <Dialog
        closable={true}
        header="Reschedule order"
        visible={rescheduleDialogVisible}
        draggable={false}
        dismissableMask={true}
        style={{ width: "35vw" }}
        onHide={hideRescheduleDialog}
        footer={
          <div className="mt-2">
            <Button label="Cancel" className="button-default" onClick={hideRescheduleDialog} />
            <Button
              label="Reschedule"
              className="button-primary"
              loading={false}
              onClick={() => {
                rescheduleMrOrder({ id: orderId as string, newDate: rescheduleDate })
                hideRescheduleDialog()
              }}
            />
          </div>
        }
      >
        <label>New date</label>
        <Calendar
          className="w-full mt-2"
          showIcon
          value={rescheduleDate}
          minDate={new Date()}
          dateFormat={"M d, yy"}
          onChange={(e) => updateRescheduleDate(e.target.value as Date)}
        />
      </Dialog>
      <Dialog
        closable={true}
        header="Cancel order"
        visible={cancelDialogVisible || isCancelling}
        draggable={false}
        dismissableMask={true}
        style={{ width: "35vw" }}
        onHide={handleOnHideCancel}
        footer={
          <div className="mt-2">
            <Button
              label="Cancel order"
              className="button-primary w-full"
              disabled={!cancelReason}
              loading={isCancelling}
              onClick={() => {
                cancelMrOrder({
                  serviceRequestId: orderId as string,
                  patientId: patientId as string,
                  cancelReason: cancelReason as string,
                  cancel_mode: cancelFutureOrder as string,
                })
              }}
            />
          </div>
        }
      >
        {invoice?.status === "balanced" && (
          <div className="text-orange-500 pb-4 flex items-center">
            <FontAwesomeIcon icon={faExclamationTriangle} size="2x" />
            <div className="pl-2 text-sm">
              <div className="leading-4">The order you are trying to cancel is paid</div>
              <div className="leading-4">Invoice identifier: {invoice?.identifier?.[0].value}</div>
            </div>
          </div>
        )}
        <label>Please provide a reason</label>
        <InputText
          className="w-full mt-2"
          value={cancelReason}
          onChange={(e) => updateCancelReason(e.target.value as string)}
        />
        <div className="inline-flex items-center mt-3">
          <Checkbox
            name="status"
            checked={cancelFutureOrder === "stop"}
            onChange={() => setCancelFutureOrder(cancelFutureOrder === "stop" ? "skip" : "stop")}
          />
          <label htmlFor="status" className="p-checkbox-label">
            Cancel all future orders
          </label>
        </div>
      </Dialog>
    </div>
  )
}

const INITIAL_STATE: State = {
  rescheduleDialogVisible: false,
  rescheduleDate: new Date(),
  cancelDialogVisible: false,
}

const reducer = (
  state: State,
  { type, payload }: { type: string; payload?: boolean | Date | string | MedicationKnowledge },
) => {
  switch (type) {
    case "updateRescheduleDialogVisible":
      return { ...state, rescheduleDialogVisible: payload as boolean }
    case "updateCancelDialogVisible":
      return {
        ...state,
        cancelDialogVisible: payload as boolean,
        cancelReason: payload !== false ? state.cancelReason : undefined,
      }
    case "updateRescheduleDate":
      return { ...state, rescheduleDate: payload as Date }
    case "updateCancelReason":
      return { ...state, cancelReason: payload as string }
    case "updateSelectedMK":
      return { ...state, selectedMK: payload as MedicationKnowledge | undefined }
    default:
      return state
  }
}

const useStateReducer = () => {
  const [{ rescheduleDialogVisible, cancelDialogVisible, cancelReason, selectedMK, rescheduleDate }, dispatch] =
    useReducer(reducer, INITIAL_STATE)

  const showRescheduleDialog = () => dispatch({ type: "updateRescheduleDialogVisible", payload: true })
  const hideRescheduleDialog = () => dispatch({ type: "updateRescheduleDialogVisible", payload: false })
  const showCancelDialog = () => dispatch({ type: "updateCancelDialogVisible", payload: true })
  const hideCancelDialog = () => dispatch({ type: "updateCancelDialogVisible", payload: false })
  const updateRescheduleDate = (newDate: Date) => dispatch({ type: "updateRescheduleDate", payload: newDate })
  const updateCancelReason = (reason: string) => dispatch({ type: "updateCancelReason", payload: reason })
  const updateSelectedMK = (mk?: MedicationKnowledge) => dispatch({ type: "updateSelectedMK", payload: mk })

  return {
    rescheduleDialogVisible,
    cancelDialogVisible,
    cancelReason,
    selectedMK,
    rescheduleDate,
    showRescheduleDialog,
    hideRescheduleDialog,
    showCancelDialog,
    hideCancelDialog,
    updateRescheduleDate,
    updateCancelReason,
    updateSelectedMK,
  }
}

type State = {
  selectedMK?: MedicationKnowledge
  rescheduleDialogVisible: boolean
  rescheduleDate: Date
  cancelDialogVisible: boolean
  cancelReason?: string
}

export { MedicationOrderDetails }
