import {
  faArrowRotateLeft,
  faFileCircleCheck,
  faPrint,
  faSquareArrowDown,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { parseISO } from "date-fns"
import { format } from "date-fns-tz"
import { codeableConceptAsString } from "fhir"
import { Button } from "primereact/button"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { useChartContext } from "chart-view"
import { DropdownMenu, ModulesId, SkeletonLoader, useShowSignedUrlDocument } from "commons"
import { LaboratoryOrderResults, getStatus, useLaboratoryOrder, useReleaseLabResult } from "commons/labs"
import { getOrderDate } from "commons/meds"
import { BILLING_TYPES_CODES, billingTypes, formatsByTypes } from "data"
import { useOrganizationContext, useOrganizationPractitioners } from "organization"
import { usePatientContext } from "patients"
import { getMoneyCurrencyAlt, openLinkInNewTab } from "utils"

import { useRevokeLabOrder } from "../hooks"
import { LabOrderCancelConfirmation } from "./LabOrderCancelConfirmation"
import { LaboratoryOrderDetailsInvoice } from "./LaboratoryOrderDetailsInvoice"
import { LaboratoryTaskItem } from "./LaboratoryTaskItem"

const LaboratoryOrderDetails = () => {
  const chartContext = useChartContext()
  const { patientId } = usePatientContext()
  const { currentOrganizationId, isExemptLabPayment } = useOrganizationContext()
  const { organizationPractitionersInfo } = useOrganizationPractitioners({ organizationId: currentOrganizationId })

  const [searchParams, setSearchParams] = useSearchParams()
  const [showRevokeDialog, setShowRevokeDialog] = useState(false)

  const { laboratoryOrder, tasks, invoice, isLoading, requisition, diagnosticReport } = useLaboratoryOrder(
    currentOrganizationId,
    searchParams.get("order") as string,
    patientId,
    organizationPractitionersInfo,
  )

  const { showDocument: showResults } = useShowSignedUrlDocument()

  const goToList = () => {
    searchParams.delete("order")
    setSearchParams(searchParams)
  }

  const { revokeLabOrder, isRevoking } = useRevokeLabOrder(
    () => {
      goToList()
    },
    () => setShowRevokeDialog(false),
  )

  useEffect(() => {
    chartContext.setSearchData({ showSearch: false })
  }, [])

  const { releaseResult } = useReleaseLabResult(laboratoryOrder.order?.id as string)

  const activeActions = useMemo(() => {
    const dropdownItems = [
      {
        label: "View results",
        icon: <FontAwesomeIcon icon={faPrint} size="sm" className="mr-2" />,
        disabled: !laboratoryOrder.presentedForm,
        command: () => showResults(laboratoryOrder.presentedForm ?? ""),
      },
      {
        label: "View requisition",
        icon: <FontAwesomeIcon icon={faSquareArrowDown} size="sm" className="mr-2" />,
        disabled: !requisition,
        command: () => openLinkInNewTab(requisition),
      },
      ...(laboratoryOrder.presentedForm && diagnosticReport?.id
        ? [
            {
              label: "Release to Patient",
              icon: <FontAwesomeIcon icon={faFileCircleCheck} size="sm" className="mr-2" />,
              disabled: !!diagnosticReport.released,
              command: () => releaseResult(diagnosticReport.id as string),
            },
          ]
        : []),
      {
        icon: (
          <FontAwesomeIcon
            icon={
              laboratoryOrder.order?.status === "draft" || laboratoryOrder.order?.status === "on-hold"
                ? faTrash
                : faArrowRotateLeft
            }
            size="sm"
            className="mr-1"
          />
        ),
        label:
          laboratoryOrder.order?.status === "draft" || laboratoryOrder.order?.status === "on-hold"
            ? "Discard"
            : "Cancel",
        command: () => setShowRevokeDialog(true),
      },
    ]

    return laboratoryOrder.revocable ? dropdownItems : dropdownItems.slice(0, -1)
  }, [showResults, laboratoryOrder, openLinkInNewTab, requisition, diagnosticReport?.released])

  const showInvoice = useCallback(() => {
    chartContext.showModule({ module: ModulesId.INVOICE, moduleParams: { invoiceId: invoice?.id ?? "" } })
  }, [chartContext, invoice?.id])

  const isInsuranceLab = useMemo(
    () => laboratoryOrder.billingType === BILLING_TYPES_CODES.INSURANCE,
    [laboratoryOrder.billingType],
  )

  const labOrderStatus = getStatus(laboratoryOrder.order)

  const cancelReason = () => {
    if (labOrderStatus?.code === "revoked" && tasks?.length) {
      const orderLabsTask = tasks.find((task) => task.code?.coding?.some((cc) => cc.code === "order-labs"))

      return orderLabsTask ? orderLabsTask.statusReason?.text : ""
    }
  }
  const isDraftOrder = labOrderStatus?.code === "draft"

  return (
    <div className="flex flex-col h-full p-3 overflow-hidden">
      {isLoading ? (
        <>
          <SkeletonLoader loaderType="two-lines" extraLine repeats={3} />
        </>
      ) : (
        <div className="@container flex flex-col flex-1 gap-4 overflow-hidden pb-4">
          <div className="flex flex-col border-b pb-4 gap-1">
            <div className="flex justify-between">
              <span title="Performer" className="text-primary font-medium text-lg">
                {laboratoryOrder.order?.performer?.[0].display ?? "unspecified"}
              </span>
              <div className="inline-flex items-center gap-2">
                {laboratoryOrder.presentedForm && (
                  <Button
                    className="p-button-sm p-button-text"
                    label={activeActions[0].label}
                    icon={activeActions[0].icon}
                    onClick={activeActions[0].command}
                    loading={isLoading || isRevoking}
                  />
                )}
                {activeActions.length > 1 && (
                  <DropdownMenu dropdownMenuItems={activeActions.slice(1)} loading={isLoading || isRevoking} />
                )}
              </div>
            </div>
            <div className="flex text-sm flex-wrap gap-3 justify-between gap-x-5">
              <div className="flex gap-1">
                <label className="text-gray-900">Requester:</label>
                <span className="text-gray-400">{laboratoryOrder.requester}</span>
              </div>
              <div className="flex gap-1">
                <label className="text-gray-900">Date:</label>
                <span className="text-gray-400">{getOrderDate(laboratoryOrder.order)}</span>
              </div>
              {!isDraftOrder && (
                <div className="flex gap-1">
                  <label className="text-gray-900">Lab Reference ID:</label>
                  <span className="text-gray-400">{laboratoryOrder.orderIdentifier}</span>
                </div>
              )}
              <div className="flex gap-1">
                <label className="text-gray-900">Status:</label>
                <span className="text-gray-400 capitalize">
                  {labOrderStatus?.code === "revoked" ? "cancelled" : labOrderStatus?.display ?? "unspecified"}
                </span>
              </div>
              <div className="flex gap-1">
                <label className="text-gray-900">Billing type:</label>
                <span className="text-gray-400">
                  {billingTypes.find(({ code }) => code === laboratoryOrder.billingType)?.display}
                </span>
              </div>
              {!isExemptLabPayment && !isInsuranceLab && (
                <div className="flex gap-1">
                  <label className="text-gray-900">Price:</label>
                  <span className="text-gray-400">{`${getMoneyCurrencyAlt(
                    laboratoryOrder.price?.currency,
                  )}${laboratoryOrder.price?.value?.toFixed(2)}`}</span>
                </div>
              )}
              {laboratoryOrder.specimenDate && (
                <div className="flex gap-1">
                  <label className="text-gray-900">Blood drawn in the office on:</label>
                  <span className="text-gray-400">
                    {format(parseISO(laboratoryOrder.specimenDate), formatsByTypes.LONG_DATETIME_WITH_TIMEZONE)}
                  </span>
                </div>
              )}
              {laboratoryOrder.order?.insurance?.[0]?.display && (
                <div className="flex gap-1">
                  <label className="text-gray-900">Insurance:</label>
                  <span className="text-gray-400">{laboratoryOrder.order.insurance[0].display}</span>
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-col gap-4 overflow-y-scroll p-2 divide-y">
            {!!diagnosticReport?.conclusion && (
              <pre className="rounded-lg p-2 bg-gray-50 text-sm">{diagnosticReport?.conclusion}</pre>
            )}
            <LaboratoryOrderResults
              panels={laboratoryOrder.panels}
              combo={laboratoryOrder.combo}
              previousResults={laboratoryOrder.previousResults}
              isInsuranceLab={isInsuranceLab}
              isOrgExemptLabPayment={isExemptLabPayment}
              orderId={laboratoryOrder.order?.id as string}
            />

            {!!laboratoryOrder.order?.reasonCode?.length && (
              <div className="flex flex-col gap-4 [&:not(:first-child)]:pt-8 [&:not(:first-child)]:mt-6">
                <span className="text-primary font-semibold text-sm">Reason codes</span>
                <div className="text-sm rounded-lg border p-4 text-gray-500">
                  <ul className="grid grid-cols-2 gap-2">
                    {laboratoryOrder.order.reasonCode.map((reason, index) => (
                      <li
                        key={
                          reason.id ?? (reason.coding?.[0].code as string) + index ?? (reason.text as string) + index
                        }
                        className="text-wrap whitespace-normal break-words"
                      >
                        {reason.coding
                          ? `${codeableConceptAsString(reason)} - ${reason.coding?.[0]?.code}`
                          : reason.text}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            {labOrderStatus?.code === "revoked" && (
              <div className="flex flex-col gap-4 [&:not(:first-child)]:pt-8 [&:not(:first-child)]:mt-6">
                <span className="text-primary font-semibold text-sm">Cancellation Reason</span>
                <div className="text-sm rounded-lg border p-4 text-gray-500">
                  <span>{cancelReason()}</span>
                </div>
              </div>
            )}
            {!!tasks?.length && (
              <div className="flex flex-col gap-4 pt-8 mt-6 border-t">
                <span className="text-primary font-semibold text-sm">Tasks</span>
                <ul className="flex flex-col gap-4">
                  {tasks?.map((task) => <LaboratoryTaskItem key={task.id} task={task} />)}
                </ul>
              </div>
            )}

            {invoice && (
              <div className="flex flex-col gap-4 pt-8 mt-6 border-t">
                <span className="text-primary font-semibold text-sm">Invoice</span>
                <ul>
                  <LaboratoryOrderDetailsInvoice
                    invoice={invoice}
                    onClick={showInvoice}
                    className="rounded-lg border cursor-pointer overflow-hidden"
                  />
                </ul>
              </div>
            )}
          </div>
        </div>
      )}

      {showRevokeDialog && (
        <LabOrderCancelConfirmation
          laboratoryOrder={laboratoryOrder}
          isCancelling={isRevoking}
          onHide={() => setShowRevokeDialog(false)}
          onCancel={revokeLabOrder}
        />
      )}
    </div>
  )
}

export { LaboratoryOrderDetails }
