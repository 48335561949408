import { Invoice, ServiceRequest } from "fhir"
import { Button } from "primereact/button"
import { MenuItem } from "primereact/menuitem"
import { FC } from "react"

import { SYSTEM_VALUES } from "system-values"
import { getIdentifierBySystem, getMoneyCurrencyAlt } from "utils"

import { DropdownMenu } from "../../components/DropdownMenu"
import { getOrderDate } from "../transformers"

const MedicationOrderDetailHeader: FC<Props> = ({
  serviceRequest,
  invoice,
  activeActions,
  menuIsLoading,
  className,
}) => {
  const lifeFileId = serviceRequest?.identifier?.find(
    ({ system }) => system === SYSTEM_VALUES.LIFEFILE_MEDICATION_ORDER,
  )
  const medOrderIdentifier = serviceRequest?.identifier?.find(({ system }) => system === SYSTEM_VALUES.MEDICATION_ORDER)

  return (
    <div className={className}>
      <div className="flex justify-between">
        <span className="inline-flex space-x-1">
          <p
            title="Order number"
            className="font-medium text-primary text-lg"
          >{`${medOrderIdentifier?.value ?? "Unspecified number"}`}</p>
        </span>
        <div className="inline-flex items-center gap-2">
          <Button
            className="p-button-sm p-button-text"
            label={activeActions[0].label}
            icon={activeActions[0].icon}
            onClick={activeActions[0].command}
            disabled={activeActions[0].disabled}
            loading={activeActions[0].loading}
          />
          {activeActions.length > 1 && (
            <Button
              className="p-button-sm p-button-text"
              label={activeActions[1].label}
              icon={activeActions[1].icon}
              onClick={activeActions[1].command}
              disabled={activeActions[1].disabled}
              loading={activeActions[1].loading}
            />
          )}

          {activeActions.length > 2 && (
            <DropdownMenu dropdownMenuItems={activeActions.slice(2)} loading={menuIsLoading} />
          )}
        </div>
      </div>
      <div className="flex justify-between text-sm">
        <div className="flex gap-1">
          <label className="text-gray-900">Requester:</label>
          <span className="text-gray-400">{serviceRequest?.requester?.display ?? "unspecified"}</span>
        </div>
        <div className="flex gap-1">
          <label className="text-gray-900">Invoice:</label>
          <span className="text-gray-400">{`#${
            getIdentifierBySystem(invoice?.identifier, SYSTEM_VALUES.INVOICE_INDENTIFIER)?.value ?? "unspecified number"
          }`}</span>
        </div>
        <div className="flex gap-1">
          <label className="text-gray-900">Date:</label>
          <span className="text-gray-400">{getOrderDate(serviceRequest)}</span>
        </div>
        <div className="flex gap-1">
          <label className="text-gray-900">Status:</label>
          <span className="text-gray-400 capitalize">
            {(serviceRequest?.status !== "revoked" ? serviceRequest?.status : "cancelled") ?? "unspecified"}
          </span>
        </div>
        {!!lifeFileId && (
          <div className="flex gap-1">
            <label className="text-gray-900">LifeFile ID:</label>
            <span className="text-gray-400 capitalize">{lifeFileId?.value}</span>
          </div>
        )}
        <div className="flex gap-1">
          <label className="text-gray-900">Price:</label>
          <span className="text-gray-400">{`${getMoneyCurrencyAlt(
            invoice?.totalGross?.currency,
          )}${invoice?.totalGross?.value?.toFixed(2)}`}</span>
        </div>
      </div>
    </div>
  )
}

type Props = {
  serviceRequest?: ServiceRequest
  invoice?: Invoice
  activeActions: (MenuItem & { command(): void; loading?: boolean })[]
  menuIsLoading?: boolean
  className?: string
}

export { MedicationOrderDetailHeader }
